<template>
    <div class="">
        <!-- Content Header (Page header) -->
        <div class="">
            <!-- Main content -->
            <section class="content">
                <div class="container-fluid">
                    <!-- Main row -->
                    <div class="row">
                        <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
                            <div class="card">
                                <div class="card-body table-responsive table-head">
                                     <div class="row bor-bot pb-2">
                                    <div class="col-md-8 pl-0">
                                       <h1 class="m-0 text-dark pt-2 pb-2">{{ this.getSiteProps('general.admin-team-report-title') || 'Club Registration Reports' }}</h1>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="text-right pb-3">
                                        <ol class="breadcrumb float-sm-right">
                                        <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                                        <li class="breadcrumb-item"><Breadcrumbs/></li>
                                        </ol>
                                      </div>
                                    </div>
                                  </div>
                                    <div class="table-responsive mt-4">
                                    <div class="data-tablebutn pb-3">
                                        <b-button class="btn-primary btn-darkblue btn mr-1" :class="{ 'filter-applied': filtersApplied }" v-b-modal.modal-club-report>
                                            <i class="fas fa-sliders-h"></i> Filters
                                        </b-button>
                                        <b-button class="btn-primary btn-darkblue btn" @click="refreshList" v-if="filtersApplied">
                                            <i class="fas fa-redo"></i> Reset
                                        </b-button>
                                        <b-button class="ml-1 btn-primary btn-darkblue btn" @click="exportCsv" :disabled="exportProgress">
                                          <i class="fas" :class="[ exportProgress ? 'fa-circle-notch fa-spin' : 'fa-file-download' ]"></i> Export
                                        </b-button>
                                    </div>
                                        <table class="table table-sm table-bordered table-hover" id="list_table">
                                            <thead class="thead-dark">
                                            <tr>
                                                <th class="all">{{this.getSiteProps('general.admin-team') || 'Club'}} Id</th>
                                                <th class="all">{{this.getSiteProps('general.admin-team') || 'Club'}} Name</th>
                                                <th class="all">Type</th>
                                                <th class="all">Status</th>
                                              <th class="all">Last Name</th>
                                              <th class="all">First Name</th>
                                              <th class="all">Email</th>
                                                <th class="all">Admin Name</th>
                                                <th class="all">Effective From</th>
                                                <th class="all">Effective To</th>
                                                <th class="all">Waiver Signed On</th>
                                                <th class="all">Waiver Expiration On</th>
                                                <th class="all">Membership</th>
                                                <th class="all">Status</th>
                                                <th class="none">Transaction Id</th>
                                                <th class="all">Region</th>
                                                <th class="all">Date Paid</th>
                                                <th class="none">League</th>
                                                <th class="none">Age Division</th>
                                                <th class="none">Recruiting</th>
                                                <th class="none">Head Coach Email</th>
                                                <th class="none">Head Coach Address</th>
                                                <th class="none">Head Coach Phone Number</th>
                                                <th class="none">State</th>
                                                <th class="none">Coupon Code</th>
                                                <th class="all">Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <!-- Data will be placed here -->
                                            <tr>
                                                <td colspan="9">&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td colspan="9">&nbsp;</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <b-modal id="modal-club-report" v-bind:hide-footer="true" ref="modal-club-report" size="xl"  title="Advanced Search">
                                        <p style="margin-top: -20px;margin-bottom: 30px "><strong class="text-primary">Note:</strong> <span class=" text-muted">Press Ctrl+click for multiple selection filters</span></p>
                                        <form ref="form" method="post" @submit.prevent="advancedSearch()">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label class="form-label">{{this.getSiteProps('general.admin-team') || 'Club'}} Id</label>
                                                        <input type="text" class="form-control" id="confirmation_code" v-model="searchForm.confirmation_code">
                                                    </div>
                                                 </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label class="form-label">{{this.getSiteProps('general.admin-team') || 'Club'}} Name</label>
                                                        <input id="club_name" type="text" class="form-control" v-model="searchForm.club_name">
                                                        <div class="text-sm text-danger">{{ errors.club_name }}</div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3" v-if="subdomain != 'alliancefastpitch'">
                                                    <div class="form-group">
                                                        <label for="club_type" class="form-label">{{ subdomain == 'waterski' ? 'Primary Club Type' : 'Type' }}</label><br>
                                                        <Select2
                                                            v-model="searchForm.club_type"
                                                            name="club_type"
                                                            id="club_type"
                                                            :options="getClubTypes"
                                                            :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-md-3" v-if="subdomain == 'waterski'">
                                                  <div class="form-group">
                                                    <label for="secondary_type" class="form-label">Secondary Club Type</label><br>
                                                    <Select2
                                                        v-model="searchForm.secondary_type"
                                                        name="secondary_type"
                                                        id="secondary_type"
                                                        :options="getClubTypes"
                                                        :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}"
                                                    />
                                                  </div>
                                                </div>
                                                 <div class="col-md-3" v-if="subdomain != 'alliancefastpitch'">
                                                    <div class="form-group">
                                                        <label class="form-label">{{ subdomain == 'waterski' ? 'First Name' : 'Admin Name' }}</label>
                                                        <input type="text" id="first_name" class="form-control" v-model="searchForm.first_name">
                                                    </div>
                                                </div>
                                                <div class="col-md-3" v-if="subdomain == 'waterski'">
                                                    <div class="form-group">
                                                        <label class="form-label">Last Name</label>
                                                        <input type="text" id="last_name" class="form-control" v-model="searchForm.last_name">
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                  <div class="form-group">
                                                      <label class="form-label">Registration From Date</label>
                                                      <div class="input-group birthdate">
                                                          <div class="birthdaypicker">
                                                              <date-pick
                                                                  v-model="searchForm.registration_start_date"
                                                                  :format="'MM/DD/YYYY'"
                                                                  :displayFormat="'MM/DD/YYYY'"
                                                                  v-mask="'##/##/####'"
                                                                  :parseDate="parseDatePick"
                                                              ></date-pick>
                                                          </div>
                                                      </div>
                                                      <input id="registration_start_date" type="hidden" class="form-control" :value="registration_start_date_1">
                                                      <div class="text-sm text-danger">{{ errors.registration_start_date }}</div>
                                                  </div>
                                              </div>
                                              <div class="col-md-3">
                                                  <div class="form-group">
                                                      <label class="form-label">Registration To Date</label>
                                                      <div class="input-group birthdate">
                                                          <div class="birthdaypicker">
                                                              <date-pick
                                                                  v-model="searchForm.registration_end_date"
                                                                  :format="'MM/DD/YYYY'"
                                                                  :displayFormat="'MM/DD/YYYY'"
                                                                  v-mask="'##/##/####'"
                                                                  :parseDate="parseDatePick"
                                                              ></date-pick>
                                                          </div>
                                                      </div>
                                                      <input id="registration_end_date" type="hidden" class="form-control" :value="registration_end_date_1">
                                                      <div class="text-sm text-danger">{{ errors.registration_end_date }}</div>
                                                  </div>
                                              </div>
                                              <div class="col-md-3">
                                                <div class="form-group">
                                                  <label class="form-label">Expiration From Date</label>
                                                  <div class="input-group birthdate">
                                                    <div class="birthdaypicker">
                                                      <date-pick
                                                          v-model="searchForm.expiration_start_date"
                                                          :format="'MM/DD/YYYY'"
                                                          :displayFormat="'MM/DD/YYYY'"
                                                          v-mask="'##/##/####'"
                                                          :parseDate="parseDatePick"
                                                      ></date-pick>
                                                    </div>
                                                  </div>
                                                  <input id="expiration_start_date" type="hidden" class="form-control" :value="expiration_start_date_1">
                                                  <div class="text-sm text-danger">{{ errors.expiration_start_date }}</div>
                                                </div>
                                              </div>
                                              <div class="col-md-3">
                                                <div class="form-group">
                                                  <label class="form-label">Expiration To Date</label>
                                                  <div class="input-group birthdate">
                                                    <div class="birthdaypicker">
                                                      <date-pick
                                                          v-model="searchForm.expiration_end_date"
                                                          :format="'MM/DD/YYYY'"
                                                          :displayFormat="'MM/DD/YYYY'"
                                                          v-mask="'##/##/####'"
                                                          :parseDate="parseDatePick"
                                                      ></date-pick>
                                                    </div>
                                                  </div>
                                                  <input id="expiration_end_date" type="hidden" class="form-control" :value="expiration_end_date_1">
                                                  <div class="text-sm text-danger">{{ errors.expiration_end_date }}</div>
                                                </div>
                                              </div>
                                                 <div class="col-md-3" v-if="subdomain=='alliancefastpitch' ">
                                                    <div class="form-group">
                                                        <label class="form-label">Registration Year</label>
                                                        <select id="season" class="form-control" v-model="searchForm.season">
                                                            <option selected="selected" value="">Select</option>
                                                          <option v-for="(season, id) in seasons.slice().sort((a, b) => b.id - a.id).reverse()" :value="season.id" :key="id">{{ season.text }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-3" v-else>
                                                    <div class="form-group">
                                                        <label class="form-label">Registration Year</label>
                                                        <select id="year1" class="form-control" v-model="searchForm.year">
                                                            <option selected="selected" value="">Select</option>                                                            
                                                            <template v-for="year in years">
                                                                <option v-if="year >= 2018" :value="year" :key="'year' + year">{{ year }}</option>
                                                            </template>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-3" v-if="Object.keys(membershipData).length > 1">
                                                        <div class="form-group">
                                                            <label class="form-label">Membership</label>
                                                          <select id="membership_id" name="membership_id" class="form-control" v-model="searchForm.membership_id">
                                                            <option value="">Select</option>
                                                            <option v-for="(id, val) in membershipData" :value="val" :key="val">{{ id }}</option>
                                                          </select>
                                                        </div>
                                                    </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label class="form-label">Status</label>
                                                        <!-- <input type="text" class="form-control"> -->
                                                        <select id="status" name="status" class="form-control" v-model="searchForm.status">
                                                            <option value="">Select</option>
                                                            <template v-for="(id, val) in membershipStatus">
                                                                <option v-if="val > 0 && val < 3" :value="val" :key="val">{{ id }}</option>
                                                            </template>
                                                        </select>
                                                    </div>
                                                </div>
                                              <div class="col-md-3">
                                                <div class="form-group">
                                                  <label class="form-label">Transaction Start Date</label>
                                                  <div class="input-group birthdate">
                                                    <div class="birthdaypicker">
                                                      <date-pick
                                                          v-model="searchForm.transaction_start_date"
                                                          :format="'MM/DD/YYYY'"
                                                          :displayFormat="'MM/DD/YYYY'"
                                                          v-mask="'##/##/####'"
                                                          :parseDate="parseDatePick"
                                                      ></date-pick>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-md-3">
                                                <div class="form-group">
                                                  <label class="form-label">Transaction End Date</label>
                                                  <div class="input-group birthdate">
                                                    <div class="birthdaypicker">
                                                      <date-pick
                                                          v-model="searchForm.transaction_end_date"
                                                          :format="'MM/DD/YYYY'"
                                                          :displayFormat="'MM/DD/YYYY'"
                                                          v-mask="'##/##/####'"
                                                          :parseDate="parseDatePick"
                                                      ></date-pick>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-md-3">
                                                <div class="form-group">
                                                    <label class="form-label">Transaction Id</label>
                                                    <input type="text" class="form-control" id="transaction_id" v-model="searchForm.transaction_id">
                                                </div>
                                               </div>
                                              <div class="col-md-3" v-if="subdomain == 'waterski'">
                                                <div class="form-group">
                                                  <label class="form-label">Region</label>
                                                  <Select2
                                                      v-model="searchForm.region_name"
                                                      name="region_name"
                                                      id="region_name"
                                                      :options="regionsListSelect2"
                                                      :settings="{ multiple: true, placeholder: 'All Regions'}"
                                                  />
                                                </div>
                                              </div>
                                              <div class="col-md-3" v-if="subdomain == 'waterski'">
                                                    <div class="form-group">
                                                        <label class="form-label">Primary Site State</label>
                                                        <select id="site_state" class="form-control" v-model="searchForm.site_state" name="searchForm.site_state" >
                                                            <option value="">Select</option>
                                                            <option  v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-3" v-if="subdomain == 'waterski'">
                                                    <div class="form-group">
                                                        <label class="form-label">Club Directory</label>
                                                        <select id="club_directory" class="form-control" v-model="searchForm.club_directory" name="searchForm.club_directory" >
                                                            <option value="">Select</option>
                                                            <option value="1">Yes</option>
                                                            <option value="0">No</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label class="form-label">Coupon Code</label>
                                                        <Select2 v-model="searchForm.coupon_code" name="coupon_code" id="coupon_code" :options="couponcode" :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />
                                                    </div>
                                                </div>
                                                <div class="col-md-3" v-if="subdomain == 'alliancefastpitch'">
                                                    <div class="form-group">
                                                        <label class="form-label">League</label>
                                                        <select class="form-control" v-model="searchForm.member_league" id="member_league">
                                                            <option value="">Select</option>
                                                            <option v-for="(leagueData,key) in teamLeague" :key="key" :value="leagueData.id">
                                                            {{ leagueData.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                              <div class="col-md-3" v-if="subdomain == 'alliancefastpitch'">
                                                <div class="form-group">
                                                  <label class="form-label">State</label>
                                                  <select id="state_code" class="form-control" v-model="searchForm.state_code" name="searchForm.state_code" >
                                                    <option value="">Select</option>
                                                    <option  v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                                                  </select>
                                                </div>
                                              </div>
                                              <div v-if="subdomain == 'alliancefastpitch'" class="col-md-3">
                                                <div class="form-group">
                                                  <label for="age_division" class="form-label">Division </label>
                                                  <select class="form-control" @change="getTeam()" id="age_division"
                                                          v-model="searchForm.age_division" name="playerTransferForm.age_division">
                                                    <option value="">Select</option>
                                                    <option v-for="(listOfAgeDivision,key) in ageDivisionOPt" :key="key"
                                                            :name="listOfAgeDivision.text" :value="listOfAgeDivision.id">
                                                      {{ listOfAgeDivision.text }}
                                                    </option>
                                                  </select>
                                                  <span class="text-sm text-danger"></span>
                                                </div>
                                              </div>
<!--                                                 <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label">Type</label>
                                                            <select class="form-control">
                                                                <option>Select</option>
                                                                <option>3-Event - AWS</option>
                                                                <option>Collegiate - NCW</option>
                                                                <option>Kneeboard - AKA</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label">
                                                                Gender
                                                            </label>
                                                           <select class="form-control">
                                                                <option>Select</option>
                                                                <option>Male</option>
                                                                <option>Female</option>
                                                                <option>Gender</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label">Name</label>
                                                            <input type="text" class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label">Confirmation Code</label>
                                                            <input type="text" class="form-control">
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label">Registration Start Date</label>
                                                            <input type="text" class="form-control" v-model="searchForm.registration_start_date">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label">Registration End Date</label>
                                                            <input type="text" class="form-control" v-model="searchForm.registration_end_date">
                                                            <div class="text-sm text-danger">{{ errors.registration_end_date }}</div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label">Zip</label>
                                                            <input type="text" class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label">Country</label>
                                                            <input type="text" class="form-control">
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label">Mode</label>
                                                            <input type="text" class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label">Donation</label>
                                                            <input type="text" class="form-control">
                                                        </div>
                                                    </div>-->

<!--                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label">
                                                                Status
                                                            </label>
                                                           <select class="form-control">
                                                                <option>Select</option>
                                                                <option>Male</option>
                                                                <option>Female</option>
                                                                <option>Gender</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                     <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label">Diversity</label>
                                                            <select class="form-control">
                                                                <option>Select</option>
                                                                <option>3-Event - AWS</option>
                                                                <option>Collegiate - NCW</option>
                                                                <option>Kneeboard - AKA</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label">
                                                                Affiliate
                                                            </label>
                                                           <select class="form-control">
                                                                <option>Select</option>
                                                                <option>Male</option>
                                                                <option>Female</option>
                                                                <option>Gender</option>
                                                            </select>
                                                        </div>
                                                    </div>-->

                                                <div class="col-md-12 modal-searchbtn text-center">
                                                    <div class="form-group">
                                                        <button type="submit" class="btn btn-primary btn-blue">{{ button.SearchButton }}</button>
                                                    </div>
                                                    <div class="text-sm text-danger">{{ errors.not_found }}</div>
                                                </div>
                                            </div>
                                        </form>
                                    </b-modal>
                                </div>
                            </div>
                        </section>
                    </div>
                    <!-- /.row (main row) -->
                </div><!-- /.container-fluid -->
            </section>
        </div>
        <!-- /.content -->
        <Footer></Footer>
    </div>
</template>

<script>

// import DownloadExcel from "vue-json-excel";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import moment from 'moment';
import $ from "jquery";
import DatePick from "vue-date-pick";
import axios from 'axios';

const validateName = name => {
    if (name.length || name != "") {
        return { valid: true, error: null };
    }
    return { valid: false, error: null };
};

let cachedData = {};
  export default {
      components: { DatePick },
    data() {
      return {
          searchValue:'',
          exportProgress: false,
          filtersApplied: false,
          loaded: false,
          perPage: 10,
          currentPage: 1,
          fields: [
              {
                key :'team_name',
                label :'Team Name',
                sortable: true
              },
              {
                key :'type',
                label :'Type',
                sortable: true
              },
               {
                key :'first_name',
                label :'First Name',
                sortable: true
              },
              {
                key :'last_name',
                label :'Last Name',
                sortable: true
              },
              {
                key :'updated_at',
                label :'Registered',
                sortable: true
              },
              {
                  key: 'status',
                  label :'Status',
                  sortable: true,
              },
              {
                  key: 'transaction_id',
                  sortable: true,
              },
            {
                key: 'actions',
                sortable: false,
            },
        ],
          items: [],
          viewEntryOptions: [
              { value: 5, text: '5' },
              { value: 10, text: '10' },
              { value: 20, text: '20' },
               { value: 20, text: '50' },
                { value: 20, text: '100' },
          ],
          filter: null,
          filterOn: [],
          infoModal: {
              id: 'info-modal',
              title: '',
              content: ''
          },
          couponcode:[],
          searchForm: {
            confirmation_code:'',
            first_name: '',
            last_name: '',
            registration_start_date :'',
            registration_end_date:'',
            expiration_start_date:'',
            expiration_end_date:'',
            transaction_start_date: '',
            transaction_end_date: '',
            membership_id: '',
            club_name: '',
            club_type: '',
            secondary_type: '',
            year: '',
            status: '',
            transaction_id: '',
            region_name:'',
            site_state:'',
            club_directory:'',
            member_league:'',
            age_division: '',
            season:'',
            state_code:'',
            coupon_code:''
          },
          button: {
              SearchButton: 'Search'
          },
          errors: {},
          table: {},
          clubTypes: [],
          membershipData: [],
          teamLeague:[],
          disciplines:[],
          seasons:[],
          disciplines_list:[],
          ageDivisionOPt: [
            {"id": "4", "text": "12U"},
            {"id": "5", "text": "10U"}
          ]
        }
     },
      methods: {
          formatDate(value)
        {
        var date = moment(value, "YYYY-MM-DD hh:mm:ss");
        if(date.isValid()){
        return date.format('MM/DD/YYYY');
        } else {
        return 'N/A';
        }
        },
          onFiltered(filteredItems) {
              // Trigger pagination to update the number of buttons/pages due to filtering
              this.totalRows = filteredItems.length
              this.currentPage = 1
          },
          viewRow(item, index, button){
              this.infoModal.title = item.first_name + ' ' + item.last_name;
              this.infoModal.content = item;
              this.$root.$emit('bv::show::modal', this.infoModal.id, button);
          },
          resetInfoModal() {
              this.infoModal.title = ''
              this.infoModal.content = ''
          },
          editRow(id){
              console.log('Editing row...' + id);
          },
          deleteRow(id){
              console.log('Deleting row...' + id);
          },
          loadData: function (){
              //if(Object.keys(cachedData).length === 0){
              console.log(this.searchForm.state);
              axios.post(process.env.VUE_APP_URL + 'api/reports/registration/Club', this.searchForm)
                  .then((response) => {
                      console.log(response.data.registrations);
                      cachedData = response.data.registrations;
                      this.items = cachedData;
                      this.loaded = true;
                      this.button.SearchButton = 'Search';
                      this.items = cachedData;
                      this.loaded = true;
                      if(this.loaded == true) {
                          console.log(this.button.SearchButton);
                          this.$refs['modal-club-report'].hide();
                      }
                      return true;
                  })
                  .catch(error => this.errors = error.response.data);
              //}

          },
          Search(event) {
            this.term = this.$route.query.term;
            if(this.term == ''){
              return true;
            }
            this.searchValue = this.$route.query.term;
            if(this.searchValue !== undefined) {
              this.searchValue = $("#globalSearch").val();
              const dataTable = $("#list_table").DataTable();
              dataTable.search(this.searchValue).draw();
            }
          },
          advancedSearch() {

              if(!this.validate()) {
                  return;
              }
              console.log(this.button.SearchButton);
              this.button.SearchButton = 'Searching...';
              console.log(this.button.SearchButton);
              // Prevent modal from closing
              //bvModalEvt.preventDefault()
              this.loaded = false;
              // Trigger submit handler
              this.table.draw();
              this.filtersApplied = true;
              //console.log(this.button.SearchButton);
              this.$refs['modal-club-report'].hide();
              this.button.SearchButton = 'Search';
          },
          // Custom Validation Rules
          validate() {
              this.errors = {}
              this.valid =  false;
              console.log('-------------');
              console.log(this.searchForm);
              const validStartDate = validateName(this.searchForm.registration_start_date);
              this.errors.registration_start_date = validStartDate.error;

              const validEndDate = validateName(this.searchForm.registration_end_date);
              this.errors.registration_end_date = validEndDate.error;

            const validExpirationStartDate = validateName(this.searchForm.expiration_start_date);
            this.errors.expiration_start_date = validExpirationStartDate.error;

            const validExpirationEndDate = validateName(this.searchForm.expiration_end_date);
            this.errors.expiration_end_date = validEndDate.error;

              var dateValid = false;
                if(validStartDate.valid && !this.validateDate(this.searchForm.registration_start_date)){
                    dateValid = true;
                    this.errors.registration_start_date = "Invalid Date";
                }
                if(validEndDate.valid && !this.validateDate(this.searchForm.registration_end_date)){
                    dateValid = true;
                    this.errors.registration_end_date = "Invalid Date";
                }

                if(dateValid == false){
                    if((validStartDate.valid && validEndDate.valid) && (moment(this.searchForm.registration_end_date).isBefore(this.searchForm.registration_start_date))) {
                        this.errors.registration_end_date = 'End date must be greater than Start date!';
                        return this.valid =  false;
                    } else {
                        this.valid = true;
                    }
                } else {
                    this.valid = false;
                }

            var expirationDateValid = false;
            if(validExpirationStartDate.valid && !this.validateDate(this.searchForm.expiration_start_date)){
              expirationDateValid = true;
              this.errors.expiration_start_date = "Invalid Date";
            }
            if(validExpirationEndDate.valid && !this.validateDate(this.searchForm.expiration_end_date)){
              dateValid = true;
              this.errors.expiration_end_date = "Invalid Date";
            }

            if(expirationDateValid == false){
              if((validExpirationStartDate.valid && validExpirationEndDate.valid) && (moment(this.searchForm.expiration_end_date).isBefore(this.searchForm.expiration_start_date))) {
                this.errors.expiration_end_date = 'End date must be greater than Start date!';
                return this.valid =  false;
              } else {
                this.valid = true;
              }
            } else {
              this.valid = false;
            }

              return this.valid;
          },
          refreshList(event){
            // this.table.clear().draw();
            this.searchForm = {
                confirmation_code:'',
                first_name: '',
                last_name: '',
                registration_start_date :'',
                registration_end_date:'',
                expiration_start_date:'',
                expiration_end_date:'',
                transaction_start_date: '',
                transaction_end_date: '',
                membership_id: '',
                club_name: '',
                club_type: '',
                secondary_type: '',
                year: '',
                status: '',
                transaction_id: '',
                member_league:'',
                age_division: '',
                season:'',
                coupon_code:''
            };
            this.table.ajax.reload();
            this.filtersApplied = false;
          },
        exportCsv(){
            var self = this;
            $.ajax({
                url: process.env.VUE_APP_URL + "api/reports/export/member-registrations/Club",
                type: 'post',
                "data": this.table.ajax.params(),
                beforeSend: function(){
                    self.exportProgress = true;
                },
                "success": function(res, status, xhr) {
                    self.exportProgress = false;
                    if(res.file_url != undefined) window.open(res.file_url, '_blank');
                },
                error: function(err){
                    console.log(err);
                    self.exportProgress = false;
                }
            });
        },
        getMembership(){
          axios.get(this.basePath + "api/getMemberships/club")
              .then((response) => {
                this.membershipData = response.data.data;
              })
              .catch(error=>{
                console.log("error getting Membership info")
              })
        },
        getLeague()
        {
            axios.get(this.basePath + "api/league")
            .then(response => {
                this.teamLeague = response.data.data;
            })
            .catch(err => {
                console.log(err);
            })
        },
        getTeam() {
          let leagueId = this.searchForm.league;
          let teams = [];
          this.teamLeague.forEach(league => {
            if(this.searchForm.league == league.id)
            {
              teams = league.team;
            }
          });

          this.leagueTeam = [];
          teams.forEach(code => {
            if(this.searchForm.age_division == code.age_division){
              this.leagueTeam.push({ id: code.id, text: code.name });
            }
          });
        },
        getDiscipline() {
          axios.get(this.basePath + "api/getDisciplines")
              .then(response => {
                this.disciplines = response.data.data;
                let discipline_codes = Object.keys(this.disciplines);
                if (discipline_codes.length && this.disciplines_list.length == 0) {
                  discipline_codes.forEach(code => {
                    this.disciplines_list.push({
                      id: code,
                      text: this.disciplines[code] == "NA - N/A" ? "N/A" : this.disciplines[code]
                    });
                  });
                }
                this.ageDivisionOPt = this.disciplines_list;
              })
              .catch(err => {
                // Manage the state of the application if the request
                // has failed
              })
        },
        getSeason()
        {
            axios.get(this.basePath+'api/get_season', { headers: this.adminHeaders })
                .then((res) => { 
                    this.seasons = res.data.data;
                })
                .catch((error) => {
                    console.log('Error:'+ error);               
                    return false;            
                }).finally(() => {
                    
                }
            );  
        },
        getCoupon() {
        axios
            .get(this.basePath + "api/coupons/index")
            .then((response) => {
            let coupon = response.data.couponList;
            let coupon_codes = Object.keys(coupon).sort();
            coupon_codes.forEach(code => {
                this.couponcode.push({ id: code, text: code });

            });
            })        
            .catch(error => {this.errors = error.response.data.couponList});
        },
      },
      mounted(){
        this.getClubType();
        this.getMembershipStatus();
        this.getMembership();
        this.getCoupon();
        if(this.subdomain == 'alliancefastpitch')
        {
            this.getLeague();
            this.getDiscipline();
            this.getSeason();
        }
        if(this.subdomain === 'waterski') {
          this.getRegions();
        }
          $.ajaxSetup({
              headers: {
                  'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
              }
          });
          var self = this;
          this.table = $("#list_table").DataTable({
              sDom: "lfrtip",
              processing: true,
              serverSide: true,
              pageLength: 10,
              "bFilter": true,
              ajax: {
                  url: process.env.VUE_APP_URL + "api/reports/registration/Club",
                  type: 'post',
                  data: function (d) {
                    d.confirmation_code = $('#confirmation_code').val() || self.searchForm.confirmation_code;
                    d.team_name = $('#club_name').val() || self.searchForm.club_name;
                    d.team_type= $('#club_type').val() || self.searchForm.club_type;
                    d.tc_first_name = $('#first_name').val() || self.searchForm.first_name;
                    d.last_name = $('#last_name').val() || self.searchForm.last_name;
                    // d.email = $('#email').val();
                    d.registration_start_date = $('#registration_start_date').val() || self.searchForm.registration_start_date;
                    d.registration_end_date = $('#registration_end_date').val() || self.searchForm.registration_end_date;
                    d.expiration_start_date = $('#expiration_start_date').val() || self.searchForm.expiration_start_date;
                    d.expiration_end_date = $('#expiration_end_date').val() || self.searchForm.expiration_end_date;
                    d.year = $('#year1').val() || self.searchForm.year;
                    d.membership_id = $('#membership_id').val() || self.searchForm.membership_id;
                    d.status = $('#status').val() || self.searchForm.status;
                    d.transaction_start_date = $("#transaction_start_date").val() || self.searchForm.transaction_start_date;
                    d.transaction_end_date = $("#transaction_end_date").val() || self.searchForm.transaction_end_date;
                    d.transaction_id = $('#transaction_id').val() || self.searchForm.transaction_id;
                    d.region_name = $('#region_name').val() || self.searchForm.region_name;
                    d.site_state = $('#site_state').val() || self.searchForm.site_state;
                    d.club_directory = $('#club_directory').val() || self.searchForm.club_directory;
                    d.member_league = $('#member_league').val() || self.searchForm.member_league;
                    d.team_age_division = $('#age_division').val() || self.searchForm.age_division;
                    d.season = $('#season').val() || self.searchForm.season;
                    d.state_code = $('#state_code').val() || self.searchForm.state_code;
                    d.active_coupon_code = $('#coupon_code').val() || self.searchForm.coupon_code;

                    if(self.subdomain === "waterski"){
                      d.team_type_secondary = $('#secondary_type').val() || self.searchForm.secondary_type;
                    }
                  }
              },
              columns: [
                  { data: 'confirmation_code', name: 'member.confirmation_code', render: (data)=>{return data || "N/A";}},
                  { data: 'team_name', name: (this.subdomain=='alliancefastpitch') ? 'registration.team.name' :'team.name', render: value => value || 'N/A'},
                  { data: 'team_type', name: 'team.type', render: (data, type, row, meta)=>{return (row.team && row.team.club_type != undefined) ? row.team.club_type : (data || 'N/A');}},
                  { data: 'team.club_status', name: 'team.club_status', render: (data, type, row, meta)=>{return (row.team && row.team.club_status != undefined) ? row.team.club_status : (data || 'N/A');}, orderable: false, searchable: false, visible: false},
                { data: "tc_last_name", name: "team.team_contacts.last_name", orderable: true, searchable: false, render: (data, type, row, meta)=>{return (row.team && row.team.team_contacts && row.team.team_contacts.last_name != undefined) ? row.team.team_contacts.last_name :( data || "N/A");} },
                { data: "tc_first_name", name: "team.team_contacts.first_name", orderable: true, searchable: false, render: (data, type, row, meta)=>{return (row.team && row.team.team_contacts && row.team.team_contacts.first_name != undefined) ? row.team.team_contacts.first_name :( data || "N/A");} },
                { data: "tc_email", name: (this.subdomain=='alliancefastpitch') ? 'team.email' :'team.team_contacts.email', orderable: true, searchable: false, render: (data, type, row, meta)=>{return (row.team && row.team.team_contacts && row.team.team_contacts.email != undefined && this.subdomain=='alliancefastpitch') ? row.team.team_contacts.email :( data || "N/A");} },
                  { data: "tc_first_name", name: "team.team_contacts.first_name", orderable: false, searchable: true, render: (data, type, row, meta)=>{return (row.team && row.team.team_contacts && row.team.team_contacts.first_name != undefined) ? row.team.team_contacts.first_name :( data || "N/A");} },
                  { data: "start_date", name: "member.start_date", orderable: true, searchable: true, render: (data)=>{return this.formatDate(data)} },
                  { data: "valid_thru", name: "member.valid_thru", orderable: true, searchable: true, render: (data)=>{return this.formatDate(data)} },
                  { data: "waiver_signed_at", name: "member.valid_thru", orderable: true, searchable: true, render: (data)=>{return data || "N/A";} },
                  { data: "waiver_expired_at", name: "member.valid_thru", orderable: true, searchable: true, render: (data)=>{return data || "N/A";} },
                  { data: "membership_type", name: "membership.name", render: (data)=>{return data || "N/A";} },
                  { data: "reg_status", name: "reg_status", orderable: false, render: (data)=>{return data || "N/A";} },
                  { data: "transaction_id", name: "registration.transaction_id", render: (data)=>{return data || "N/A";} },
                  { data: "region_code", name: "registration.region.name", render: (data)=>{return data || "N/A";}, visible: false, searchable: false },
                  { data: "transaction_date", name: "registration.transaction_date", render: (data)=>{return this.formatDate(data) || "N/A";} },
                  { data: "member_league", name: "registration.team.member_league", render: (data)=>{return data || "N/A"},searchable: false, visible:this.subdomain == "alliancefastpitch" },
                  { data: "age_division", name: "registration.team.age_division", render: (data)=>{return data || "N/A"}, visible: this.subdomain == "alliancefastpitch",searchable: false },
                  { data: "membership_benefits", name: "membership_benefits", render: (data)=>{return data || "N/A"}, visible: this.subdomain == "alliancefastpitch",searchable: false },
                  { data: "head_coach_email", name: "head_coach_email", render: (data)=>{return data || "N/A"}, visible: this.subdomain == "alliancefastpitch",searchable: false },
                  { data: "head_coach_address", name: "head_coach_address", render: (data)=>{return data || "N/A"}, visible: this.subdomain == "alliancefastpitch",searchable: false },
                  { data: "head_coach_phone", name: "head_coach_phone", render: (data)=>{return data || "N/A"}, visible: this.subdomain == "alliancefastpitch",searchable: false },
                  { data: "state", name: "team.state", orderable: true, render: (data)=>{return data || "N/A"},visible: this.subdomain == "alliancefastpitch" },
                  { data: "coupon_code", name: "coupon_code",  render: (data)=>{return data || "N/A";}, visible: true, orderable : false },
                  { data: "actions", name: "actions", orderable: false, searchable: false, render: (data,type,row,meta) => {
                    var clublink = (this.subdomain=='alliancefastpitch') ? 'team-registrations/'+row.registration[0].team_id:'club-registrations/'+row.team.id; 
                    return `<a href="/admin/${clublink}"><i class="fas fa-eye"></i></a>`} }
              ],
              columnDefs: [
                  { targets: 16, orderable: false, visible: this.subdomain=='alliancefastpitch' },
              ],
              order: [[1, "asc"]]
          });
          var vueRouter = this.$router;
            document.addEventListener("click", function(e){
                if(e.target && e.target.parentNode.className == "actions-click"){
                    var id = e.target.parentNode.getAttribute('data-id');
                    var clubrouterview = (this.subdomain == 'alliancefastpitch') ? '/admin/team-registrations/'+ id : '/admin/club-registrations/'+ id;
                    vueRouter.push(clubrouterview);
                }
            });

        if(this.subdomain == "shooting"){
            this.table.columns([4,5,6,14]).visible(false);
        } else {
          if(this.subdomain == "alliancefastpitch")
            this.table.columns([2,7]).visible(false);
          else
            this.table.columns([7,12]).visible(false);
        }

        //Shooting club types load
        if(this.subdomain == 'shooting'){
            const retry = setInterval(() => {
                this.clubTypes = this.getSiteProps('memberRegistration.club.fields.club_info.club_type.choices');
                if(this.clubTypes != "") clearInterval(retry);
            }, 200);
        }
        this.Search();
      },
      created(){
          //this.loadData();
      },

      computed: {
        rows() {
            return this.items.length
        },
        searchTerm() {
          return this.$route.query.term;
        },
        years () {
          let currentYear = new Date().getFullYear();
          let yearsArray = [];
          for (let year = currentYear + 1; year >= 2018; year--) {
            yearsArray.push(year);
          }
          return yearsArray;
        },
        registration_start_date_1: function() {
            return this.searchForm.registration_start_date;
        },
        registration_end_date_1: function() {
            return this.searchForm.registration_end_date;
        },
        expiration_start_date_1: function() {
          return this.searchForm.expiration_start_date;
        },
        expiration_end_date_1: function() {
          return this.searchForm.expiration_end_date;
        },
        getClubTypes: function (){
          let types = [];
          if(this.subdomain === "shooting") types = this.clubTypes;
          else {
            let keys = Object.keys(this.clubType);
            if(keys.length){
              types = keys.map(key => {
                return { id: key, text: this.clubType[key]  }
              });
            }
          }
          return types;
        },
        regionsListSelect2(){
          var keys = Object.keys(this.regionsList);
          return keys.map(item => {
            return { id: this.regionsList[item].code, text: this.regionsList[item].name };
          })
        },
      },
    watch: {
      searchTerm(newValue) {
        if (newValue !== '') {
          this.Search();
        }
      }
    }
    }

</script>